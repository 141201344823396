import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ArticleRoundedIcon from "@mui/icons-material/ArticleRounded";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import pavilionLogo from "../../../img/logos/pavilion-logo.svg";
import useSupplierEditUrl from "../../hooks/useSupplierEditUrl";
import { pageTypeState } from "../../jotai/page";
import { projectsLoadable } from "../../jotai/projects";
import {
  buyerProfileState,
  intakeFormEnabledState,
  logoutCallback,
  profileTypeState,
  requireIntakeFormEnabledState,
} from "../../jotai/user";
import { Button, Link } from "../../library";
import { useShowInviteTeamModal } from "../../modals/InviteTeamModal";
import { getProjectId } from "../../utils";
import { COMPANY_NAME } from "../../utils/constants";
import {
  INVITE_TEAM_SOURCE,
  PageType,
  ProfileType,
  SearchSource,
  SidebarLinkType,
} from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/features";
import { useSearchModal } from "../SearchModal/SearchModal";
import QuoteRequestSidebarCard from "./QuoteRequestCard";
import SidebarLink from "./SidebarLink";

function SidebarSection({
  className,
  children,
}: { className?: string; children: React.ReactNode }) {
  return (
    <div
      className={clsx("px-2 py-4 flex flex-col gap-4 items-start", className)}
    >
      {children}
    </div>
  );
}

function SidebarLinkContainer({
  className,
  children,
}: { className?: string; children: React.ReactNode }) {
  return <div className={clsx("w-full grid gap-1", className)}>{children}</div>;
}

export default function Sidebar() {
  const showSearchModal = useSearchModal();
  const showInviteTeamModal = useShowInviteTeamModal();
  const supplierEditUrl = useSupplierEditUrl();

  const { governmentAgency } = useAtomValue(buyerProfileState);
  const profileType = useAtomValue(profileTypeState);
  const intakeFormEnabled = useAtomValue(intakeFormEnabledState);
  const projectsList = useAtomValue(projectsLoadable);
  const pageType = useAtomValue(pageTypeState);
  const requireIntakeForm = useAtomValue(requireIntakeFormEnabledState);
  const projectId = getProjectId();

  const logOut = useAtomCallback(logoutCallback());

  const showIntake = isFeatureEnabled("projectBrowsing") && intakeFormEnabled;

  const projects = projectsList.state === "hasData" ? projectsList.data : [];

  return (
    <nav
      className="w-[240px] hidden md:flex flex-col flex-none min-h-screen max-h-screen border-r fixed top-0 box-content overflow-y-auto bg-cp-neutral-palette-50 divide-y"
      data-testid="sidebar-nav"
    >
      <SidebarSection>
        <Link
          className="analytics-logo-link mt-2 mx-2"
          href="/welcome"
          target="_parent"
          newWindow={false}
        >
          <img
            className="h-7"
            src={pavilionLogo}
            alt={`${COMPANY_NAME} logo`}
          />
        </Link>
        <SidebarLinkContainer>
          <SidebarLink
            sidebarLinkType={SidebarLinkType.HOME}
            active={pageType === PageType.PROFILE}
            Icon={HomeOutlinedIcon}
            activeIcon={HomeRoundedIcon}
            label="Home"
            href="/welcome"
            dataTestId="sidebar-nav-home-link"
          />
          {!requireIntakeForm && (
            <SidebarLink
              sidebarLinkType={SidebarLinkType.SEARCH}
              active={
                pageType === PageType.CONTRACT_SEARCH &&
                (projectId === "" ||
                  !projects.some((project) => project.id === projectId))
              }
              Icon={SearchRoundedIcon}
              label="Search"
              onClick={() => {
                showSearchModal({
                  source: SearchSource.SIDEBAR,
                  isLocationRelevant: true,
                  newWindow: false,
                  autoFocus: true,
                });
              }}
              dataTestId="sidebar-nav-search-link"
            />
          )}
          {!requireIntakeForm && governmentAgency?.id && (
            <SidebarLink
              sidebarLinkType={SidebarLinkType.ENTITY_CONTRACTS}
              active={pageType === PageType.ENTITY_CONTRACTS}
              Icon={AccountBalanceOutlinedIcon}
              activeIcon={AccountBalanceRoundedIcon}
              label="My entity contracts"
              href="/entity-contracts"
            />
          )}
        </SidebarLinkContainer>
      </SidebarSection>
      {governmentAgency?.id && (
        <SidebarSection className="flex-grow">
          {showIntake && (
            <Button
              as={Link}
              className="w-fit"
              size={Button.sizes.SMALL}
              badgeProps={{ Icon: AddRoundedIcon }}
              linkProps={{
                href: "/intake",
                newWindow: false,
                underline: false,
              }}
              dataTestId="sidebar-nav-new-request-button"
            >
              New request
            </Button>
          )}
          <SidebarLinkContainer>
            <SidebarLink
              sidebarLinkType={SidebarLinkType.REQUESTS}
              active={pageType === PageType.PROJECT}
              Icon={FolderOutlinedIcon}
              activeIcon={FolderRoundedIcon}
              label={showIntake ? "Requests" : "All projects"}
              href="/requests"
            />
            {/* TODO: Paginate in the backend */}
            {projects.slice(0, 7).map((project) => (
              <SidebarLink
                className="ml-2"
                key={project.id}
                sidebarLinkType={SidebarLinkType.PROJECT}
                label={project.name}
                href={`/requests/${project.id}`}
                Icon={ArticleOutlinedIcon}
                activeIcon={ArticleRoundedIcon}
                active={projectId === project.id}
              />
            ))}
            {projects.length > 7 && (
              <SidebarLink
                className="ml-2"
                sidebarLinkType={SidebarLinkType.PROJECT}
                label={`+ ${projects.length - 7} more`}
                href="/requests"
                active={false}
              />
            )}
          </SidebarLinkContainer>
        </SidebarSection>
      )}
      {profileType === ProfileType.SUPPLIER && (
        <SidebarSection>
          <SidebarLinkContainer>
            <SidebarLink
              sidebarLinkType={SidebarLinkType.SUPPLIER_CONTRACTS}
              active={
                pageType === PageType.SUPPLIER_EDIT &&
                window.location.hash === ""
              }
              Icon={ArticleOutlinedIcon}
              activeIcon={ArticleRoundedIcon}
              label="Business profile"
              href={supplierEditUrl || ""}
              dataTestId="sidebar-nav-business-profile-link"
            />
            <SidebarLink
              sidebarLinkType={SidebarLinkType.SUPPLIER_EDIT}
              active={
                pageType === PageType.SUPPLIER_EDIT &&
                window.location.hash === "#contracts"
              }
              Icon={BusinessCenterOutlinedIcon}
              activeIcon={BusinessCenterRoundedIcon}
              label="Contracts"
              href={supplierEditUrl?.replace("edit", "manage-contracts") || ""}
            />
          </SidebarLinkContainer>
        </SidebarSection>
      )}
      <SidebarSection>
        {governmentAgency?.id && <QuoteRequestSidebarCard />}
        <SidebarLinkContainer>
          <SidebarLink
            sidebarLinkType={SidebarLinkType.INVITE_TEAM}
            active={false}
            Icon={GroupAddOutlinedIcon}
            label="Invite your team"
            onClick={() =>
              showInviteTeamModal({
                inviteSource: INVITE_TEAM_SOURCE.SIDEBAR,
              })
            }
          />
          <SidebarLink
            sidebarLinkType={SidebarLinkType.PREFERENCES}
            active={pageType === PageType.ACCOUNT}
            Icon={SettingsOutlinedIcon}
            activeIcon={SettingsRoundedIcon}
            label="Account preferences"
            href="/profile"
          />
          <SidebarLink
            sidebarLinkType={SidebarLinkType.LOGOUT}
            active={false}
            Icon={LogoutOutlinedIcon}
            label="Log out"
            onClick={logOut}
          />
        </SidebarLinkContainer>
      </SidebarSection>
    </nav>
  );
}
