import clsx from "clsx";
import type { ElementType } from "react";
import { Badge, Link } from "../../library";
import type { LinkProps } from "../../library/Link";
import { iconColorClass, textColorClass } from "../../utils/colors";
import type { SidebarLinkType } from "../../utils/enums";

export default function SidebarLink({
  sidebarLinkType,
  Icon,
  activeIcon,
  label,
  active,
  className,
  newWindow = false,
  dataTestId,
  ...linkProps
}: LinkProps & {
  sidebarLinkType: SidebarLinkType;
  Icon?: ElementType;
  activeIcon?: ElementType;
  label: string;
  active: boolean;
}) {
  return (
    <Badge
      as={Link}
      linkProps={{
        size: "sm",
        color: active ? "neutral.boldest.enabled" : "neutral.bolder.enabled",
        emphasis: active,
        underline: false,
        newWindow,
        ...linkProps,
      }}
      Icon={active && activeIcon ? activeIcon : Icon}
      label={label}
      size="sm-md"
      iconClass={
        active
          ? iconColorClass.neutral.boldest.enabled
          : iconColorClass.neutral.bolder.enabled
      }
      className={clsx(
        "flex items-center gap-2 px-2 py-1 rounded-2 ",
        {
          "bg-cp-transparent-dark-100": active,
          "hover:bg-cp-transparent-dark-50": !active,
          [textColorClass.neutral.boldest.enabled]: active,
        },
        className
      )}
      includeMargin={false}
      dataTestId={dataTestId}
    />
  );
}
