import { useState } from "react";
import linkedin from "../../img/icons/LinkedIn.svg";
import pavilionLogo from "../../img/logos/pavilion-logo-white.svg";
import { LINKEDIN_URL, SUPPORT_EMAIL_ADDRESS } from "../utils/constants";

export default function Footer() {
  const [year] = useState(() => new Date().getFullYear());

  return (
    <footer id="root">
      <div className="w-full text-cp-neutral-0 bg-cp-midnight-300 text-cp-body-sm px-6 md:px-8 py-10">
        <div className="flex flex-row items-center">
          <img src={pavilionLogo} className="h-10" alt="Pavilion logo" />
          <a href={LINKEDIN_URL} className="ml-auto" target="_new">
            <img className="h-7" src={linkedin} alt="LinkedIn logo" />
          </a>
        </div>
        <div className="flex flex-row flex-wrap w-full items-start mt-10 gap-8">
          <div className="gap-3 flex flex-col">
            <h6 className="font-semibold">Product</h6>
            <a href="/" className="analytics-footer-product">
              Search the marketplace
            </a>
            <a href="/our-product#webinar" className="analytics-footer-webinar">
              Schedule a walkthrough
            </a>
            <a href="/categories" className="analytics-footer-categories">
              Categories
            </a>
            <a href="/bug-report" className="analytics-footer-bug-report">
              Report a bug
            </a>
          </div>
          <div className="gap-3 flex flex-col">
            <h6 className=" font-semibold">Resources</h6>
            <a
              href="/about/public-entities"
              className="analytics-footer-for-public-entities"
            >
              Public entities
            </a>
            <a
              href="/about/suppliers"
              className="analytics-footer-for-suppliers"
            >
              Suppliers
            </a>
            <a
              href="/about/resources"
              className="analytics-footer-all-resources"
            >
              All resources
            </a>
          </div>
          <div className="gap-3 flex flex-col">
            <h6 className="font-semibold">Company</h6>
            <a
              href="/about/pavilion"
              target="_new"
              className="analytics-footer-team"
            >
              About us
            </a>
            <a
              href="/about/careers"
              target="_new"
              className="analytics-footer-careers"
            >
              Careers
            </a>
            <a
              href="/about/news"
              target="_new"
              className="analytics-footer-news"
            >
              In the news
            </a>
            <a
              href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}
              className="analytics-footer-contact"
            >
              Contact
            </a>
          </div>
          <div className="gap-3 ml-auto flex flex-col text-right">
            <h6 className="font-bold tracking-wider uppercase">
              © Pavilion {year}
            </h6>
            <a
              href="/privacy"
              target="_new"
              className="analytics-footer-privacy"
            >
              Privacy policy
            </a>
            <a href="terms" target="_new" className="analytics-footer-terms">
              Terms of use
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
