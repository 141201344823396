import AccountBalanceOutlined from "@mui/icons-material/AccountBalanceOutlined";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import pluralize from "pluralize";
import { useMemo } from "react";
import {
  buyerProfileState,
  intakeFormEnabledState,
  requireIntakeFormEnabledState,
  userState,
} from "../../../jotai/user";
import { buyerWelcomePageState } from "../../../jotai/welcome";
import { Badge, PageSection, Typography } from "../../../library";
import { ContractIcon } from "../../../shared/CustomIcons/ContractIcon";
import GetHelpNotice from "../../../shared/GetHelpNotice";
import { getHeaderTopClass } from "../../../shared/Header/constants";
import SearchBarCard from "../../../shared/SearchBarCard";
import { recommendedSearchesByAgencyType } from "../../../shared/SuggestedSearches";
import { bgColorClass, textColorClass } from "../../../utils/colors";
import { SearchSource } from "../../../utils/enums";
import { isFeatureEnabled } from "../../../utils/features";
import { QuoteRequestBanner } from "../../QuoteRequest/QuoteRequestBanner";
import BuyerFAQ from "./BuyerFAQ";
import PartnershipUpsellCard from "./PartnershipUpsellCard";
import ProjectsCard from "./ProjectsCard";
import QuickLinks from "./QuickLinks";
import StartPurchaseCard from "./StartPurchaseCard";

function BuyerSummary() {
  const { firstName } = useAtomValue(userState);
  const { governmentAffiliationDisplayName } = useAtomValue(buyerProfileState);
  const { numContracts, numTeamMembers } = useAtomValue(buyerWelcomePageState);

  return (
    <>
      <Typography
        variant="display"
        className="sm:text-cp-display-md text-cp-display-sm"
        color="brand.boldest.enabled"
      >
        Welcome{firstName ? `, ${firstName}` : ""}
      </Typography>
      <div className="flex flex-wrap gap-y-1 gap-x-2 lg:mb-4">
        <Badge
          size="sm-md"
          Icon={AccountBalanceOutlined}
          className={textColorClass.neutral.bolder.enabled}
        >
          <Typography size="sm" color="neutral.bolder.enabled">
            {governmentAffiliationDisplayName}
          </Typography>
        </Badge>
        <div>·</div>
        <Badge
          size="sm-md"
          Icon={ContractIcon}
          className={textColorClass.neutral.bolder.enabled}
        >
          <Typography size="sm" color="neutral.bolder.enabled">
            {numContracts} {pluralize("contract", numContracts)}
          </Typography>
        </Badge>
        <div>·</div>
        <Badge
          size="sm-md"
          Icon={GroupRoundedIcon}
          className={textColorClass.neutral.bolder.enabled}
        >
          <Typography size="sm" color="neutral.bolder.enabled">
            {numTeamMembers} team {pluralize("member", numTeamMembers)}
          </Typography>
        </Badge>
      </div>
      <QuickLinks />
    </>
  );
}

export default function BuyerProfilePage() {
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const { recentSearches, cpoAnalytics, cpoUpsell } = useAtomValue(
    buyerWelcomePageState
  );
  const intakeFormEnabled = useAtomValue(intakeFormEnabledState);
  const requireIntakeForm = useAtomValue(requireIntakeFormEnabledState);
  const suggestedSearches = useMemo(() => {
    if (recentSearches.length) return recentSearches;

    const agencyType = governmentAgency?.agencyType || "";
    const recommendedSearchKey =
      agencyType && agencyType in recommendedSearchesByAgencyType
        ? agencyType
        : "DEFAULT";
    return recommendedSearchesByAgencyType[recommendedSearchKey];
  }, [recentSearches, governmentAgency]);
  const showAnalytics = cpoAnalytics || cpoUpsell;

  const showPrimaryIntake =
    isFeatureEnabled("projectBrowsing") && requireIntakeForm;
  const showIntake =
    !showPrimaryIntake &&
    isFeatureEnabled("projectBrowsing") &&
    intakeFormEnabled;
  const showProjects = !showIntake && !showPrimaryIntake && !showAnalytics;
  return (
    <div>
      <PageSection>
        <div
          className={clsx(
            "absolute left-0 right-0 -z-1 h-[14rem] bg-gradient-to-b from-cp-persimmon-50 to-cp-white-100",
            getHeaderTopClass()
          )}
        />
        <div className="py-10 grid gap-6 grid-cols-12">
          <div className="grid gap-3 col-span-full lg:col-span-4 h-fit">
            <BuyerSummary />
          </div>
          <div className="grid gap-6 col-span-full lg:col-span-8">
            {showPrimaryIntake ? (
              <>
                <StartPurchaseCard />
                <ProjectsCard />
              </>
            ) : (
              <SearchBarCard
                searchSource={SearchSource.BUYER_WELCOME_PAGE}
                suggestedSearchTerms={suggestedSearches}
                suggestedSearchTitle={
                  recentSearches.length > 1
                    ? "Recent searches"
                    : "Example searches"
                }
              />
            )}
            {showIntake && <StartPurchaseCard />}
            {showAnalytics && <PartnershipUpsellCard />}
            <QuoteRequestBanner />
            {showProjects && <ProjectsCard />}
          </div>
        </div>
      </PageSection>
      <PageSection className={bgColorClass.brand.subtlest.enabled}>
        <div className="grid py-10 gap-6 grid-cols-12">
          <div className="grid gap-6 col-span-full md:col-span-4 order-last md:order-first h-fit">
            <GetHelpNotice subtitle="Schedule time to talk to our team." />
          </div>
          <div className="grid col-span-full md:col-span-8">
            <BuyerFAQ />
          </div>
        </div>
      </PageSection>
    </div>
  );
}
