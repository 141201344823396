import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";

import { Link } from "../../library";
import type { MenuItemProps } from "../../library/Dropdown/DropdownMenu";

function HeaderLink({
  href,
  target,
  label,
  analyticsClassName,
  onClick,
}: MenuItemProps) {
  const [currentPageMatchesLink, setCurrentPageMatchesLink] = useState(false);
  const resetLinks = useCallback(() => {
    if (typeof window !== "undefined") {
      let cleanedPath = window.location.pathname + window.location.hash;
      cleanedPath = cleanedPath.endsWith("/")
        ? cleanedPath.slice(0, -1)
        : cleanedPath;
      setCurrentPageMatchesLink(href === cleanedPath);
    }
  }, [href]);

  useEffect(() => {
    window.addEventListener("hashchange", resetLinks);
    return () => {
      window.removeEventListener("hashchange", resetLinks);
    };
  }, [resetLinks]);

  useEffect(() => {
    resetLinks();
  }, [resetLinks]);

  return (
    <Link
      href={href}
      onClick={onClick}
      variant="meta"
      target={target || "_parent"}
      color="default"
      emphasis
      underline={currentPageMatchesLink}
      className={clsx(analyticsClassName, "mr-4 whitespace-nowrap", {
        "underline decoration-cp-lapis-500 decoration-2 underline-offset-8":
          currentPageMatchesLink,
      })}
    >
      {label}
    </Link>
  );
}

interface HeaderLinkListProps {
  links: MenuItemProps[];
}

export default function HeaderLinkList({ links }: HeaderLinkListProps) {
  return (
    <div className="hidden lg:block">
      {links.map((link) => (
        <HeaderLink key={link.label} {...link} />
      ))}
    </div>
  );
}
